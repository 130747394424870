html,
body {
    height: 100%;
}

.pad-l-15 {
    padding-left: 15px !important;
}

.pad-r-15 {
    padding-right: 15px !important;
}

.m-t-20 {
    margin-top: 20px;
}


/* Wrapper for page content to push down footer */

#wrap {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    /* Negative indent footer by it's height */
    margin: 0 auto -246px;
}

#push,
#footer {
    height: 246px;
}

body {
    font-family: 'Open Sans', sans-serif;
    color: #111;
    font-size: 14px;
    font-weight: 400;
    overflow-x: hidden;
}

a,
a:hover,
a:visited {
    color: $brand-danger;
}

a,
a:focus,
a:active {
    outline: none;
}

.form-control {
    border-radius: 0px;
}

.blackheadline {
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0px;
}

a#logo {
    position: absolute;
    display: block;
    height: 140px;
    width: 140px;
    /*background-color: #cf1f3f;*/
    background-color: $brand-danger;
    background: $brand-danger url('../img/logo_bg.png') no-repeat center center;
    top: 0px;
    left: 30px;
    z-index: 9999;
}

.searchhead {
    padding: 5px;
    padding-left: 15px;
    padding-right: 30px;
    button,
    .button {
        background-color: $brand-danger !important;
        color: #fff !important;
    }
    @media (max-width: $screen-sm) {
        padding-right: 15px;
    }

    form.search-form-head {
        float: left;
        width: 60%;

        @media (min-width: $screen-md) {
            width: auto;
            float: right;
        }
    }
}

.navbar-toggle {
    margin-top: 1px;
    background-color: #e6e7e8;
}

.navbar,
.navbar-header {
    #navbar-close {
        height: 14px;
        width: 22px;
        color: #9C9C9C;
    }
}

.navbar-default {
    /*background-color: #5d5d5d;*/
    background-color: #a7a9ab;
    margin-bottom: 0px;
    border: 0px;
    border-radius: 0px;
    height: 36px;
    z-index: 999;
    .navbar-brand {
        padding-top: 10px;
        padding-left: 30px;
        width: 250px;
        color: #fff;
        @media (max-width: $screen-md) {
            width: auto;
        }
        img.mobile-logo {
            @media (max-width: 324px) {
                width: 100px;
            }
        }
    }
    .navbar-nav {
        &>li>a {
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
            padding: 8px;
            font-weight: 300;
            &:focus,
            &:hover {
                color: #fff;
                background-color: $brand-danger;
            }
        }
        &>.active>a,
        &>.active>a:focus,
        &>.active>a:hover {
            color: #fff;
            background-color: $brand-danger;
        }
        .products-categories {
            width: 940px;
            padding: 10px;
            margin-left: -200px;
            @media (max-width: $screen-md) {
                margin-left: -300px;
            }
            @media (max-width: $screen-lg) {
                width: 760px;
            }
            li {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .product-category-item {
                    //display: inline-block;
                    display: flex;
                    flex-direction: column;
                    flex: 1 180px;
                    position: relative;
                    //width: 180px;
                    //height: 230px;
                    text-align: center;
                    vertical-align: top;
                    padding: 5px;
                    margin-bottom: 5px;
                    transition: box-shadow .3s ease 0s;
                    &:hover {
                        box-shadow: 0px 0px 8px #888;
                    }
                    // styles for IE
                    @media screen\0 {
                        flex: 1 150px;
                    }
                    a {
                        display: inline;
                        color: $brand-danger;
                        text-transform: uppercase;
                    }
                    .subcategories {
                        margin-top: auto;
                        margin-bottom: 10px;
                        color: #a7a9ab;
                        a {
                            color: #a7a9ab;
                            text-transform: none;
                            font-size: 11px;
                        }
                    }
                    .cat-product-img {
                        background-repeat: no-repeat;
                        background-position: center top;
                        background-size: contain;
                        width: 100%;
                        height: 100px;
                        margin-top: auto;
                        margin-bottom: 10px;
                        //position: absolute;
                        //bottom: 0px;
                        //left: 0px;
                    }
                }
            }
        }
    }
    .navbar-right {
        background-color: transparent;
        padding-right: 8px;
        @media (max-width: $screen-sm) {
            background-color: #a7a9ab;
        }
    }
    @media (max-width: $screen-sm) {
        z-index: 9999;
        .nav {
            background-color: #a7a9ab;
        }
    }
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
    background-color: $brand-danger;
}

@media (min-width: $screen-sm) {
    ul.nav li.dropdown:hover ul.dropdown-menu {
        display: block;
    }
}

@media (max-width: 767px) {
    ul.nav>li {
        display: inline-block;
        width: 100%;
    }
    ul.nav li.dropdown ul.dropdown-menu {
        display: block;
        background-color: transparent;
        position: relative;
        width: 100%;
        box-shadow: none;
        border: 0px;
        li {
            a {
                color: #fff;
                font-size: 14px;
                font-weight: 300;
                padding: 8px;
                text-transform: uppercase;
                position: relative;
                &:hover {
                    background-color: $brand-danger;
                }
            }
        }
    }
}

.form-control:focus {
    border-color: $brand-danger;
    box-shadow: none;
}

.btn-default.mybtn {
    color: #fff;
    background-color: #a7a9ab;
}


/* carousel */

.carousel-inner {
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

#carousel-example-generic,
.carousel-inner>.item {
    //height: 700px;
    top: -14px;
    margin-bottom: -14px;
}

.carousel-inner>.item>a>img,
.carousel-inner>.item>img {
    width: 100%;
}

.carousel-control.left,
.carousel-control.right {
    background-image: none;
    opacity: 1;
    text-shadow: 0px 0px 5px #000;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
    left: 18%;
}

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
    right: 18%;
}

.carousel-caption {
    right: 15%;
    top: 30px;
    color: #fff;
    padding-bottom: 20px;
    padding-top: 20px;
    position: absolute;
    text-align: right;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    z-index: 10;
    color: #fff;
    font-weight: 300;
    h2 {
        font-size: 50px;
        font-weight: 300;
    }
    a {
        text-transform: uppercase;
        color: $brand-danger;
    }
    .detail-link {
        position: absolute;
        bottom: 20px;
        right: 0px;
        text-shadow: none;
    }
}

.carousel-indicators {
    //bottom: 0;
    li {
        border: 1px solid #a7a9ab;
        background-color: #fff;
    }
    .active {
        background-color: #a7a9ab;
    }
}

.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }
        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }
        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }
    /*.carousel-control {
        z-index: 2;
    }*/
}


/* caroulsel end */

.breadcrumb {
    //padding-left: 250px;
    font-size: 12px;
    a {
        color: $brand-danger;
        text-decoration: underline;
    }
    .glyphicon {
        color: #111;
    }
    &>li+li::before {
        background: url('../img/point.png') no-repeat center center;
        content: "";
        margin-right: 10px;
        margin-left: 10px;
    }
    @media (min-width: $screen-md) {
        padding-left: 250px;
    }
    @media (max-width: $screen-sm) {
        /*li:first-of-type {
            margin-left: -140px;
        }*/
        position: absolute;
        width: 100%;
        z-index: 900;
    }
}

.content {
    /*margin-top: 80px;*/
    margin-bottom: 30px;
    padding-top: 50px;
    h1,
    h2 a {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
        text-transform: uppercase;
        color: $brand-danger;
    }
    .price {
        font-size: 20px;
        font-weight: 700;
    }
    .wheretobuy {
        font-size: 16px;
        font-weight: 700;
        margin-top: 0px;
        text-transform: uppercase;
    }

    @media (min-width: $screen-md) {
        padding-top: 30px;
    }
}

h3.greyhead {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: #a7a9ab;
}

.product-table {
    margin-bottom: 0px;
    &>tbody>tr>td {
        padding-top: 15px;
        padding-bottom: 15px;
        //border-color: #a7a9ab;
    }
}

.left-menu-panel {
    padding-left: 15px;
    @media (max-width: $screen-sm) {
        padding: 0px;
    }
}

.media {
    border: 1px solid #ccc;
    padding-top: 5px;
}

.filterform {
    /*background-color: #d1d3d4;*/
    padding: 10px;
    //margin-bottom: 20px;
}

h1.detailproductname {
    color: $brand-danger;
    font-weight: 700;
    margin-bottom: 5px;
}

ul.fllist {
    padding-left: 0px;
}

ul.fllist li {
    list-style: none;
    margin-left: 0px;
    background: url('../img/fl.png') no-repeat left bottom;
    padding-left: 30px;
}

.menu {
    background-color: #a7a9ab;
    //padding: 10px;
    color: #fff;
    .table tr td {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 8px 0px 8px 8px;
    }
    .table tr[class*='treegrid-parent-'] td,
    .table:first-child tr:first-child td {
        border-top: 0px;
    }
    .treegrid-expander {
        color: #fff;
    }
    .table>tbody>tr.active>td,
    .table>tbody>tr.active>th,
    .table>tbody>tr>td.active,
    .table>tbody>tr>th.active,
    .table>tfoot>tr.active>td,
    .table>tfoot>tr.active>th,
    .table>tfoot>tr>td.active,
    .table>tfoot>tr>th.active,
    .table>thead>tr.active>td,
    .table>thead>tr.active>th,
    .table>thead>tr>td.active,
    .table>thead>tr>th.active {
        background-color: $brand-danger;
    }
    a {
        color: #fff;
        font-size: 13px;
        &:hover {
            text-decoration: underline;
        }
        &.smallMenuItem {
            font-size: 12px;
        }
    }
    @media (max-width: $screen-sm) {
        margin-bottom: 15px;
    }
}

ul.nav ul.nav li a {
    margin-left: 20px;
    font-size: 12px;
    border-bottom: 0px;
}

#frm-selectLangForm {
    width: 90px;
    position: relative;
    z-index: 99999;

    @media (min-width: $screen-md) {
        margin-right: 7px;
    }
    
}

// selectric
.selectric-scroll ul li {
    text-align: left;
    img {
        margin-right: 8px;
    }
}

.selectric-form-control {
    margin-top: -30px;
    @media (max-width: $screen-sm) {
        margin-top: 0px;
    }
}

.selectric {
    p.label {
        margin-right: 25px;
        img {
            margin-right: 8px;
        }
    }

    .button {
        width: 20px;
    }
}

// products
@media(min-width: $screen-sm) {
    .main-products-content {
        padding-right: 23px;
        padding-left: 0px;
        //margin-left: -5px;
    }
    .main-products-content-filter {
        padding-right: 18px;
        padding-left: 10px;
    }
}

@media(max-width: $screen-sm) {
    /*.main-products-content {
        padding-right: 0px;
        padding-left: 25px;
    }*/
    .main-products-content-filter {
        padding-right: 10px;
        padding-left: 10px;
        margin-top: 40px;
    }
}

@media(max-width: $screen-sm) {
    .mobile-pad {
        padding-top: 40px;
    }
}

// prodejci
.sel-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: flex-start;
    .box-product {
        //@include make-md-column(4);
        //@include make-lg-column(3);
        display: flex;
        flex-direction: column;
        flex: 1 230px;
        max-width: 260px;
        //height: 250px;
        border: 1px solid #d1d3d4;
        padding: 10px;
        font-size: 13px;
        margin: 10px;
        h2 a {
            text-transform: none;
        }
        p:last-of-type {
            margin-top: auto;
        }
    }
}

img.product-action {
    position: absolute;
    top: 5px;
    left: 10px;
}

.products-catalogue {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .box-product {
        flex: 1 230px;
        max-width: 260px;
        border: 1px solid #d1d3d4;
        padding: 10px;
        font-size: 12px;
        margin: 0px 5px 10px 5px;
        width: 260px;
        position: relative;
        /*@media (min-width: $screen-lg) {
            width: 260px;
        }*/
        form {
            display: flex;
            flex-direction: column;
            height: 100%;
            a.product-img {
                display: block;
                width: 100%;
                height: 180px;
                margin-bottom: 10px;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
            }
            .box-info {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                h2 {
                    line-height: 0.8;
                    margin-top: 0px;
                    height: 32px;
                    overflow: hidden;
                    a {
                        font-size: 14px;
                        color: $brand-danger;
                        text-transform: none !important;
                        @media (max-width: $screen-md) {
                            font-size: 12px;
                        }
                    }
                }
                p {
                    height: 51px;
                    overflow: hidden;
                }
                .nav {
                    li {
                        background: url('../img/arrow.png') no-repeat left center;
                        padding-left: 15px;
                        color: #666666;
                        @media (max-width: $screen-md) {
                            font-size: 12px;
                        }
                    }
                }
            }
            .product-box-footer {
                margin-top: auto;
                a.btn-default {
                    background-color: #a7a9ab;
                    color: #fff;
                    border: 0px;
                }
            }
        }
        @media (max-width: $screen-md) {
            font-size: 12px;
        }
    }
}

.related-products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    a {
        flex: 1 230px;
        max-width: 230px;
        margin: 15px;
        text-align: center;
        img {
            margin: 5px auto;
        }
    }
}

.products-another-images {
    display: inline-block;
    width: 90px;
}

.btn-default {
    background-color: #d1d3d4;
    border-radius: 0px;
}

.btn-danger {
    background-color: $brand-danger;
    border-color: $brand-danger;
    border-radius: 0px;
    color: #fff;
    text-transform: uppercase;
}

.tab-content.specifications {
    margin-top: 0px;
    padding-top: 1px;
    padding-bottom: 1px;
    background-color: #e5e5e5;
    margin-left: 0px;
}

.tab-content.specifications h3 {
    color: $brand-danger;
    font-size: 16px;
}

.spec {
    padding-right: 0px !important;
    padding-left: 5px;
}

.nav-pills.spec>li>a {
    border-radius: 0px;
    color: #111;
    border-bottom: 1px solid #d1d3d4;
}

.nav-pills.spec>li.active>a {
    color: #000;
    font-weight: 700;
    background-color: #e5e5e5;
    border-left: 3px solid $brand-danger;
}


/* next and previous product in detail product */

a#next,
a#prev {
    position: absolute;
    top: 37%;
    font-size: 50px;
    color: #e5e5e5;
}

a#next {
    right: 2%;
}

a#prev {
    left: 2%;
}


/* homepage tabs */

.tab-content {
    margin-top: -5px;
    .row {
        margin-right: 0px;
    }
    &>.tab-pane {
        padding-top: 5px;
    }
    .leftcontab {
        padding-top: 60px;
        padding-left: 50px;
    }
    h2 {
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 30px;
    }
    p.descp {
        font-size: 16px;
        font-weight: 300;
    }
    a {
        color: #cf1f3f;
    }
}

.nav-tabs>li {
    width: 25%;
    @media (max-width: $screen-sm) {
        width: 100%;
    }
}

.nav-tabs>li>a {
    border: 1px solid #dedede;
    margin: 0px;
    border-radius: 0px;
    text-transform: uppercase;
    color: #111;
    font-size: 16px;
    text-align: center;
}

#index-slider {
    padding-left: 0px;
    padding-right: 0px;
    //border-top: 1px solid #eee;
}

#index-slider .nav-tabs>li.active>a,
#index-slider .nav-tabs>li.active>a:focus,
#index-slider .nav-tabs>li.active>a:hover {
    border: 0px;
    @media (max-width: $screen-sm) {
        border: 1px solid #dedede;
    }
}

h3.spechead {
    text-transform: uppercase;
    color: #111;
    font-size: 16px;
}

.panel-group .panel {
    border-radius: 0px;
    border: 0px;
}

.panel-default>.panel-heading {
    background-color: #a7a9ab;
    border-radius: 0px;
    color: #fff;
}

.panel-title>.small,
.panel-title>.small>a,
.panel-title>a,
.panel-title>small,
.panel-title>small>a {
    color: #fff !important;
}


/*.cezetmap {
    margin-top: -15px !important;
    z-index: 0;
}*/

.morelink {
    margin-top: 30px;
}

.morelink a {
    text-transform: uppercase;
    font-size: 14px;
}


/* footer */

#footer {
    .info {
        /*background-color: #737373;*/
        background-color: #a7a9ab;
        color: #fff;
        font-size: 12px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
        a {
            color: #fff;
        }
    }
    h3 {
        font-size: 14px;
        text-transform: uppercase;
    }
    .bot {
        /*background-color: #d1d3d4;*/
        background-color: #e6e7e8;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        color: #5c5c5c;
        font-size: 12px;
        .input-group {
            width: 70%;
        }
    }
    button {
        background-color: #cf1f3f;
        color: #fff;
    }
    @media(min-width: $screen-md) {
        .frow {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            .fcol {
                margin: 0px 60px 0px 0px;
            }
        }
    }
}

span.lb-number {
    display: none !important;
}

.footer-logo {
    padding-top: 20px;
}

@media (min-width: 1600px) {
    .carousel-caption .detail-link {
        right: -210px;
    }
}

// image gallery close button
.fr-close {
    right: 25px !important;
    .fr-close-background {
        background-image: url('../img/cancel_white.png');
        background-position: center center;
        background-repeat: no-repeat;
    }
}