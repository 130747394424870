@import 'custome/customeVariables';
@import 'bootstrap';
//@import 'jqueryui/jquery-ui';

// modify icon for checkbox
/*$font-family-icon: 'Glyphicons Halflings';
$check-icon: "\e013";

.checkbox label:after {
    padding-left: 4px;
    padding-top: 2px;
    font-size: 9px;
}*/
//@import 'checkbox/awesome-bootstrap-checkbox';

//@import 'scrollbar/jquery-scrollbar';
@import 'selectrict/selectrict';

@import 'custome/custome';